<template>
	<div class="common-sidebar-container">
		<el-collapse @change="handleChange" :accordion="true" v-model="active" v-if="dataList.length > 0">
			<el-collapse-item v-for="(item,index) in dataList" :key="item.id" :title="'模块：' + item.title"
				:name="item.id" :class="{'active' :active == item.id}">
				<!-- 是否打分完毕 -->
				<template slot="title" v-if="showIsScoring">
					模块：{{item.title}}
					<img src="@/assets/duigou.png" v-if="item.scoring" class="scoring-img">
					<img src="@/assets/chahao.png" v-else class="scoring-img">
				</template>
				<!-- 是否分组完毕 -->
				<template slot="title" v-else-if="showIsGrouping">
					模块：{{item.title}}
					<img src="@/assets/duigou.png" v-if="item.grouping" class="scoring-img">
					<img src="@/assets/chahao.png" v-else class="scoring-img">
				</template>
				<template slot="title" v-else>
					模块：{{item.title}}
				</template>
				<div class="item" v-for="(item2,index2) in item.children" :key="item2.id"
					@click="xmClick(item2.id,item2.title,item,item2)" :class="{'item-active' : xmActive == item2.id}">
					项目：{{item2.title}}
					<!-- 是否打分完毕 -->
					<img src="@/assets/duigou.png" v-if="item2.scoring && showIsScoring" class="scoring-img">
					<img src="@/assets/chahao.png" v-if="!item2.scoring && showIsScoring" class="scoring-img">
					<!-- 是否分组完毕 -->
					<img src="@/assets/duigou.png" v-if="item2.grouping && showIsGrouping" class="scoring-img">
					<img src="@/assets/chahao.png" v-if="!item2.grouping && showIsGrouping" class="scoring-img">
				</div>
			</el-collapse-item>
		</el-collapse>
		<div v-else class="zwsj">
			暂无数据
		</div>
	</div>
	
</template>

<script>
	import {
		getqueryCourseModule
	} from '@/api/index.js'
	export default {
		props: {
			showIsScoring: {
				type: Boolean,
				default: false
			},
			showIsGrouping: {
				type: Boolean,
				default: false
			}
		},
		name: "commonSidebar",
		data() {
			return {
				active: '-1',
				xmActive: -1,
				dataList: [],
				moduleName: '',
				courseId: null, //模块儿id
				show: true
			};
		},
		watch: {},
		created() {
			this.getqueryCourseModule()
			if(this.$route.query.moduleId) {
				//从竞赛详情点击开启评分的弹窗然后点击输入标准值跳过来的
				let query = this.$route.query
				console.log(query,'query')
				//默认展开模块，默认选中项目
				this.active = query.moduleId
				this.xmActive = query.xmId
				this.$emit('xmClick', 1, query.xmId, query.moduleNmae,  query.xmName, query.moduleId, '', query.scoringEnabled) //目前7个参数
			}
		},
		methods: {
			async getqueryCourseModule() {
				const res = await getqueryCourseModule({
					raceId: this.$store.state.raceInfo.id
				})
				if (res.data.code == 200) {
					this.dataList = res.data.data
				}
				// console.log(res,'getqueryCourseModule')
			},
			handleChange(val) {
				this.active = val
				// console.log(this.dataList,'this.dataList')
				// console.log(val,'val')
				this.dataList.forEach((item, index) => {
					if (val == item.id) {
						this.courseId = item.id
						this.moduleName = item.title
					}
				})
				// if(!val){
				// 	this.$emit('xmClick',-1)
				// 	this.xmActive = -1
				// }
			},
			xmClick(id, xmName, item, item2) {
				console.log(item, 'item2222222')
				if (this.xmActive == id) {
					this.xmActive = -1
					this.$emit('xmClick', -1)
				} else {
					this.xmActive = id
					this.$emit('xmClick', 1, id, this.moduleName ? this.moduleName : item.title, xmName, this.courseId ?
						this.courseId : item.id, item2.score, item2.scoringEnabled) //目前7个参数
				}
			},
			defaultUnfold(moduleId, xmId) {
				// console.log(moduleId,'moduleId')
				// console.log(xmId,'xmId')
				this.active = moduleId
				this.xmActive = xmId
			},

		}
	}
</script>


<style>
	.el-collapse-item {
		margin-bottom: 10px;
	}
</style>
<style lang="scss" scoped>
	.scoring-img {
		width: 18px;
		height: 18px;
		margin-left: 4px;
	}

	.common-sidebar-container {
		padding-top: 24px;
		max-width: 232px;
		overflow: hidden;
		background-color: #fff;

		.zwsj {
			width: 100%;
			height: 100%;
			display: flex;
			justify-content: center;
			align-items: center;
			color: #909399;
			font-size: 22px;
		}

		::v-deep .el-collapse,
		::v-deep .el-collapse-item__header {
			border: none;
			font-size: 18px;
		}

		::v-deep .el-collapse-item__header {
			padding-left: 24px;
			height: auto;
			line-height: 1.4em;
			min-height: 64px !important;
		}

		::v-deep .el-collapse-item__wrap {
			border: none;
			margin-bottom: 5px;
		}

		::v-deep .el-collapse-item__content {
			padding-top: 20px;
			padding-bottom: 0;
		}

		.item {
			max-width: 232px;
			min-height: 64px;
			cursor: pointer;
			box-sizing: border-box;
			padding-left: 24px;
			font-size: 18px;
			display: flex;
			align-items: center;
		}



		.active {
			position: relative;

			::v-deep .el-collapse-item__header {
				font-size: 18px;
				font-weight: 600;
				color: rgba(0, 0, 0, 0.89);
				background-color: #f8f7f0;
			}

			::v-deep .el-collapse-item__wrap {
				font-size: 18px;
				font-weight: 600;
				color: rgba(0, 0, 0, 0.89);
				background-color: #f8f7f0;
			}

		}

		.active::before {
			content: '';
			width: 4px;
			height: 100%;
			position: absolute;
			left: 0;
			top: 0;
			background-color: #007a69;
		}

		.item-active {
			color: #fff;
			background-color: #007a69;
		}


	}
</style>