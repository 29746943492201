import request from '@/utils/request'

// 公共-----------------------------------------------------------------------

//关联裁判长（新增)
export function refereeRaceSave(data) {
	return request({
		url: `/api/blade-refereeRace/refereeRace/save`,
		method: 'POST',
		data,
	})
}

//关联裁判长（修改)
export function refereeRaceUpdate(data) {
	return request({
		url: `/api/blade-refereeRace/refereeRace/update`,
		method: 'POST',
		data,
	})
}


//获取此赛事是否开启打分
export function enableScoringStatus(params) {
	return request({
		url: `/api/raceQuestion/module/enableScoringStatus`,
		params
	})
}

//新增赛事
export function raceSave(data) {
	return request({
		url: `/api/race/save`,
		method: 'POST',
		data,
	})
}

//赛事列表
export function getMatchList(params) {
	return request({
		url: `/api/race/list`,
		params
	})
}

//赛事列表（不分页）
export function getRaceInput(params) {
	return request({
		url: `/api/race/input`,
		params
	})
}

//复制比赛
export function copyRaceData(data) {
	return request({
		url: `/api/race/question/data/copyRaceData`,
		method: 'POST',
		data,
		params:data
	})
}

// 登录页---------------------------------------------------------------------

//登录
export function login(data) {
	return request({
		url: `/api/blade-auth/oauth/token`,
		method: 'POST',
		data,
		params: data
	})
}

// --------------------------------------------------------------- 竞赛设置页


// 获取表格列表
export function getQueryCourseModule(params) {
	return request({
		url: `/api/blade-course/course/queryCourseModule`,
		params
	})
}

// 编辑修改模块儿
export function updateCourseModule(data) {
	return request({
		url: `/api/blade-course/course/update`,
		method: 'POST',
		data
	})
}

// 新增模块儿
export function saveCourseModule(data) {
	return request({
		url: `/api/blade-course/course/save`,
		method: 'POST',
		data
	})
}

// 删除模块儿
export function deleteCourseModule(params) {
	return request({
		url: `/api/blade-course/course/deleteCourseModule`,
		method: 'POST',
		params
	})
}

// 设置保留几位小数
export function raceUpdate(data) {
	return request({
		url: `/api/race/update`,
		method: 'POST',
		data
	})
}

//查询某条赛事
export function getRaceDetail(params) {
	return request({
		url: `/api/race/detail`,
		params
	})
}

//查询当前项目下录入所有题的总分
export function getXmAllScore(params) {
	return request({
		url: `/api/raceQuestion/module/detail`,
		params
	})
}

//复制
export function copyCourseData(params) {
	return request({
		url: `/api/race/question/data/copyCourseData`,
		method: 'POST',
		params
	})
}

// --------------------------------------------------------------- 赛题录入页


//复制题
export function copyRaceQuestionData(params) {
	return request({
		url: `/api/race/question/data/copyRaceQuestionData`,
		method: 'POST',
		params
	})
}

//左侧公共模块儿树
export function getqueryCourseModule(params) {
	return request({
		url: `/api/raceQuestion/module/queryCourseModule`,
		params
	})
}

//获取题型字典
export function getDictionaryTree(params) {
	return request({
		url: `/api/blade-system/dict/dictionary-tree`,
		params
	})
}

//编辑字段查询
export function getQueryDynamicForms(params) {
	return request({
		url: `/api/blade-dynamicForms/dynamicForms/queryDynamicForms`,
		params
	})
}

//编辑字段新增修改删除
export function dynamicFormsSubmit(data) {
	return request({
		url: `/api/blade-dynamicForms/dynamicForms/submit`,
		method: 'POST',
		data
	})
}

//获取下方表格
export function queryInputQuestionList(params) {
	return request({
		url: `/api/race/question/data/queryInputQuestionList`,
		params
	})
}

//确认录入题
export function questionSave(data) {
	return request({
		url: `/api/race/question/data/save`,
		method: 'POST',
		data
	})
}

//下方表格删除
export function questionRemove(data) {
	return request({
		url: `/api/race/question/data/remove`,
		method: 'POST',
		params: data
	})
}

//表格点击编辑保存提交接口
export function questionUpdate(data) {
	return request({
		url: `/api/race/question/data/update`,
		method: 'POST',
		data
	})
}


// --------------------------------------------------------------- 裁判及塞队页


// 录入塞队信息
export function saveTeam(data) {
	return request({
		url: `/api/raceTeam/saveTeam`,
		method: 'POST',
		params: data
	})
}

// 塞队列表
export function raceTeamList(params) {
	return request({
		url: `/api/raceTeam/page`,
		params
	})
}

// 修改赛位号
export function raceTeamUpdate(data) {
	return request({
		url: `/api/raceTeam/update`,
		method: 'POST',
		data
	})
}

// 裁判页上方表格列表
export function userList(params) {
	return request({
		url: `/api/blade-system/user/user-list`,
		params
	})
}

// 裁判页当前所选裁判表格列表
export function queryRefereeList(params) {
	return request({
		url: `/api/blade-referee/referee/queryRefereeList`,
		params
	})
}

// 添加至裁判团队
export function saveReferee(params) {
	return request({
		url: `/api/blade-system/user/saveReferee`,
		method: 'POST',
		params
	})
}

// 从当前所选裁判删除
export function removeCp(params) {
	return request({
		url: `/api/blade-referee/referee/remove`,
		method: 'POST',
		params
	})
}

// 获取职称字典
export function dictionary(params) {
	return request({
		url: `/api/blade-system/dict/dictionary`,
		params
	})
}

// 新增裁判
export function userSubmit(data) {
	return request({
		url: `/api/blade-system/user/submit`,
		method: 'POST',
		data
	})
}

// 编辑裁判
export function userUpdateSubmit(data) {
	return request({
		url: `/api/blade-system/user/update`,
		method: 'POST',
		data
	})
}

// 离职裁判
export function resignation(data) {
	return request({
		url: `/api/blade-system/user/resignation`,
		method: 'POST',
		params:data
	})
}

// 选择关联裁判
export function updateEntity(data) {
	return request({
		url: `/api/blade-refereeTeam/refereeTeam/updateEntity`,
		method: 'POST',
		data
	})
}

// 移除关联裁判
export function deleteEntity(data) {
	return request({
		url: `/api/blade-refereeTeam/refereeTeam/deleteEntity`,
		method: 'POST',
		data
	})
}



// --------------------------------------------------------------- 分组设置页


// 获取题的列表
export function queryRaceQuestionDataModule(params) {
	return request({
		url: `/api/race/question/data/queryRaceQuestionDataModule`,
		params
	})
}

// 获取赛队的列表（不带分页）
export function queryTeamList(params) {
	return request({
		url: `/api/raceTeam/queryTeamList`,
		params
	})
}

// 确定分组
export function saveRefereeGroup(data) {
	return request({
		url: `/api/referee/group/saveRefereeGroup`,
		method: 'POST',
		data
	})
}

// 编辑保存分组
export function updateRefereeGroup(data) {
	return request({
		url: `/api/referee/group/updateRefereeGroup`,
		method: 'POST',
		data
	})
}

// 下面表格数据
export function queryRefereeGroupVO(params) {
	return request({
		url: `/api/referee/group/queryRefereeGroupVO`,
		params
	})
}

// 表格删除
export function deleteRefereeGroup(params) {
	return request({
		url: `/api/referee/group/deleteRefereeGroup`,
		method: 'POST',
		params
	})
}

// 复用上次题目
export function reuseQuestions(params) {
	return request({
		url: `/api/referee/group/reuseQuestions`,
		params
	})
}


// --------------------------------------------------------------- 竞赛详情页


// 获取模块儿项目列表
export function getCourseModuleList(params) {
	return request({
		url: `/api/raceQuestion/module/queryCourseModuleList`,
		params
	})
}

// 评分详情
export function queryTeamDataMark(params) {
	return request({
		url: `/api/race/question/data/queryTeamDataMark`,
		params
	})
}

// 题号列表
export function RaceQuestionDataModule(params) {
	return request({
		url: `/api/race/question/data/queryRaceQuestionDataModule`,
		params
	})
}

// 评分详情列表
export function enableScoring(params) {
	return request({
		url: `/api/raceQuestion/module/enableScoring`,
		params
	})
}

// 争议列表
export function disputedModuleData(params) {
	return request({
		url: `/api/disputed/disputedModuleData`,
		params
	})
}

// 重新打分（包括一键重新打分）
export function reRating(params) {
	return request({
		url: `/api/disputed/reRating`,
		method: 'POST',
		params,
	})
}

// 查询某一个项目开启打分的状态
export function queryModuleScoringEnabled(params) {
	return request({
		url: `/api/raceQuestion/module/queryModuleScoringEnabled`,
		params
	})
}
// --------------------------------------------------------------- 数据统计页


// 成绩单列表
export function getStatisticsTeamVo(params) {
	return request({
		url: `/api/bladeModuleScore/moduleScore/getStatisticsTeamVo`,
		params
	})
}

// 成绩单点击查看详情
export function detailStatistics(params) {
	return request({
		url: `/api/raceQuestion/score/detailStatistics`,
		params,
		data: params
	})
}

// 通过选择模块来查询项目
export function queryCourseModuleList(params) {
	return request({
		url: `/api/raceQuestion/module/queryCourseModuleList`,
		params,
	})
}

// 详细成绩页导出
export function exportDetailStatistics(params) {
	return request({
		url: `/api/raceQuestion/score/exportDetailStatistics`,
		params,
		data: params,
		responseType: 'blob',
	})
}


// 详细成绩页导出(带图片)
export function exportFilepath(params) {
	return request({
		url: `/api/raceQuestion/score/exportFilepath`,
		params,
		data: params,
		responseType: 'blob',
	})
}
