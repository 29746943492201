import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
	  tabBarActive:-1,
	  userInfo: JSON.parse(localStorage.getItem('userInfo')) ? JSON.parse(localStorage.getItem('userInfo')) : {},
	  raceInfo: JSON.parse(localStorage.getItem('raceInfo')) ? JSON.parse(localStorage.getItem('raceInfo')) : {},
	  isEnableScoringStatus:false //是否开启打分
  },
  getters: {
  },
  mutations: {
	  //设置公共头部的样式
	  setTabBarActive(state,active){
		  state.tabBarActive = active
	  },
	  //设置用户信息
	  setUserInfo: (state, userInfo) => {
	  	state.userInfo = userInfo
	  	localStorage.setItem('userInfo',JSON.stringify(userInfo))
	  },
	  //退出登录
	  logOut(state){
	  	localStorage.removeItem('userInfo')
	  	localStorage.removeItem('loginTime')
	  	localStorage.removeItem('raceInfo')
	  	state.userInfo = {}
	  	state.raceInfo = {}
	  },
	  //设置赛事信息
	  setRaceInfo(state, raceInfo){
		  state.raceInfo = raceInfo
		  localStorage.setItem('raceInfo',JSON.stringify(raceInfo))
	  }
  },
  actions: {
  },
  modules: {
  }
})
