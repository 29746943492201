import axios from 'axios'
import router from '../router'
import Vue from 'vue'
import store from '@/store'
const service = axios.create({
	baseURL: '',
	// timeout: 500000
})
let loadingInstance
// 请求拦截器
service.interceptors.request.use(
	config => {
		loadingInstance = Vue.prototype.$loading({lock: true})
		// 添加请求头等前置处理
		config.headers['Content-Type'] = 'application/json; charset=UTF-8'
		config.headers['Authorization'] = 'Basic c2FiZXI6c2FiZXJfc2VjcmV0'
		if (JSON.parse(localStorage.getItem('userInfo')) && JSON.parse(localStorage.getItem('userInfo'))
			.access_token) {
			config.headers['Blade-Auth'] = 'bearer ' + JSON.parse(localStorage.getItem('userInfo')).access_token
		}
		return config
	},
	error => {
		// 请求错误处理
		loadingInstance.close()
		console.log('Request Error:', error)
		return Promise.reject(error)
	}
)

// 响应拦截器
service.interceptors.response.use(
	response => {
		loadingInstance.close()
		return Promise.resolve(response)
	},
	error => {
		console.log(error, 'error')
		loadingInstance.close()
		if (error.response.data.code == 401) {
			router.push({
				path: '/login',
			})
			store.commit('logOut')
			Vue.prototype.$message({
				type: 'warning',
				message: '登录超时！'
			})
			// Vue.prototype.$message.error(error.response.data.msg)
			return
		}else{
			Vue.prototype.$message.error(error.response.data.msg)
			return Promise.reject(error)
		}
	}
)

export default service;