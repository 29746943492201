import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import '@/libs/rem.js'
Vue.config.productionTip = false


import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import CommonHeader from '@/components/CommonHeader.vue'
import commonSidebar from '@/components/commonSidebar.vue'

Vue.use(ElementUI);
Vue.component('CommonHeader', CommonHeader)
Vue.component('commonSidebar', commonSidebar)
Vue.filter('formatterString', function(str) {
	str = "" + str; 
	return str.replace(/&amp;/g, "&")
		.replace(/&lt;/g, "<")
		.replace(/&gt;/g, ">")
		.replace(/&quot;/g, '"')
		.replace(/&lquot;/g, '“')
		.replace(/&rquot;/g, '”')
		.replace(/&apos;/g, "'");
})

new Vue({
	router,
	store,
	render: h => h(App)
}).$mount('#app')