import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '@/store'
import {
	getQueryCourseModule
} from '@/api/index.js'
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
	return originalPush.call(this, location).catch((err) => err)
}
Vue.use(VueRouter)
const routes = [{
		path: '/',
		name: 'prePage',
		component: () => import('../views/prePage.vue'),
	},{
		path: '/entry',
		name: 'entry',
		component: () => import('../views/entry.vue'),
	},{
		path: '/login',
		name: 'login',
		component: () => import('../views/login.vue'),
	},
	{
		path: '/matchList',
		name: 'matchList',
		component: () => import('../views/matchList.vue')
	},
	{
		path: '/homeView',
		name: 'homeView',
		component: () => import('../views/homeView.vue')
	},
	{
		path: '/competitionSettings',
		name: 'competitionSettings',
		component: () => import('../views/competitionSettings.vue')
	},
	{
		path: '/competitionEnter',
		name: 'competitionEnter',
		component: () => import('../views/competitionEnter.vue')
	},
	{
		path: '/refereesAndTeams',
		name: 'refereesAndTeams',
		component: () => import('../views/refereesAndTeams.vue')
	},
	{
		path: '/dataStatistics',
		name: 'dataStatistics',
		component: () => import('../views/dataStatistics.vue')
	},
	{
		path: '/groupSettings',
		name: 'groupSettings',
		component: () => import('../views/groupSettings.vue')
	},
	{
		path: '/competitionDetails',
		name: 'competitionDetails',
		component: () => import('../views/competitionDetails.vue'),
	}
]

const router = new VueRouter({
	// mode:'history',
	routes,
	scrollBehavior: () => ({
		y: 0
	}), //滚动到顶端
})

//全局前置守卫：初始化时执行、每次路由切换前执行
router.beforeEach(async (to, from, next) => {
	// console.log('beforeEach', to.path, from.path)
	if (to.path != '/login' && to.path != '/' && to.path != '/entry') {
		if (!store.state.userInfo.access_token) {
			// Vue.prototype.$message({
			// 	type: 'warning',
			// 	message: '请先登录！'
			// });
			setTimeout(() => {
				next('/login')
			}, 1000)
			return
		}
	}
	// //进入这些页面前，现校验模块儿设置分值占比之和是否等于100%
	// if (to.path == '/competitionEnter' || to.path == '/refereesAndTeams' || to.path == '/groupSettings' ||
	// 	to.path == '/competitionDetails' || to.path == '/dataStatistics') {
	// 	const res = await getQueryCourseModule({
	// 		raceId: store.state.raceInfo.id
	// 	})
	// 	if (res.data.data.length > 0) {
	// 		let allPercentage = 0
	// 		res.data.data.forEach((item, index) => {
	// 			allPercentage += Number(item.percentage)
	// 		})
	// 		if (allPercentage != 100) {
	// 			Vue.prototype.$message({
	// 				type: 'warning',
	// 				message: '当前赛事所有模块分值占比之和不等于100%，请先设置正确后再进行操作'
	// 			});
	// 			return
	// 		}
	// 	}
	// }


	if (to.path == '/' || to.path == '/HomeView') {
		store.commit('setTabBarActive', -1)
	}
	next() //放行

})
export default router