<template>
	<div id="app">
		<router-view />
	</div>
</template>
<script>
	import {
		login
	} from '@/api/index.js'
	export default {
		components: {},
		data() {
			return {
				timer: null
			}
		},
		created() {
			this.timer = setInterval(async () => {
				if (Object.keys(this.$store.state.userInfo).length > 0 && this.$router.currentRoute.path != '/' && this.$router.currentRoute.path != '/entry') {
					//计算token有效期还剩多少秒
					let expiresIn = this.$store.state.userInfo.expires_in - (new Date().getTime().toString()
						.substr(0, 10) - localStorage.getItem('loginTime'))
					// console.log('expiresIn',expiresIn)
					if (expiresIn < 10000) {
						const res = await login({
							refresh_token: this.$store.state.userInfo.refresh_token,
							grant_type: 'refresh_token',
							scope: 'all',
							tenantId: '000000'
						})
						if (res.data.access_token) {
							this.$store.commit('setUserInfo', res.data)
							localStorage.setItem('loginTime', new Date().getTime().toString().substr(0, 10))
						} else {
							this.$message({
								message: '登录超时！即将为您自动跳转到登录页！',
								type: 'warning'
							});
							clearInterval(this.timer)
							this.$store.commit('logOut')
							setTimeout(() => {
								this.$router.replace({
									path: '/login'
								})
							}, 3000)
						}
					}
				}
			}, 10000)
		},
		destroyed() {
			console.log('destroyed')
			clearInterval(this.timer)
		},
		methods: {

		}
	}
</script>

<style>
	.el-select-dropdown__item.selected {
		color: #007A69!important;
		font-weight: 700!important;
	}

	.el-range-editor.is-active,
	.el-range-editor.is-active:hover,
	.el-select .el-input.is-focus .el-input__inner {
		border-color: #007A69;
	}

	.el-pagination__sizes .el-input .el-input__inner:hover {
		border-color: #007A69;
	}

	.el-select .el-input__inner:focus {
		border-color: #007A69;
	}

	.el-button:active {
		border-color: #007A69;
	}

	/* .el-button:focus, .el-button:hover {
	     border-color: #007A69!important;
	} */
	.el-form-item {
		display: flex;
		align-items: center;
	}
</style>
<style lang="scss">
	#app {
		// font-family: Avenir, Helvetica, Arial, sans-serif;
		font-family: Microsoft YaHei, PingFang SC, 微软雅黑, 宋体, 黑体, Helvetica Neue, Tahoma, Arial, Hiragino Sans GB;
		-webkit-font-smoothing: antialiased;
		-moz-osx-font-smoothing: grayscale;
		// text-align: center;
		// color: #2c3e50;
	}

	body {
		margin: 0;
		padding: 0;
		box-sizing: border-box;
		font-size: 16px;
		background-color: #f5f5f4;
		max-width: 100%;
		overflow-x: hidden;
	}
</style>